.main-f-card {
  width: calc(100% - 16px);
  margin: 16px 8px 16px 8px;
  background: #fff;
  border-radius: 5px;
  padding-top:16px;
  &-zero {
    width: 100%;
    margin: 16px 0 16px 0;
  }
  &-medium {
    width: calc(100% - 32px);
    margin: 16px 16px 16px 16px;
  }
  &-top {
    margin-top: -120px;
    z-index:1;
    background: #fff !important;
    position: relative;
  }
}
