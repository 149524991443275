.field-flex {
  display: flex;
  flex-direction: column;
  input, .p-dropdown, .p-calendar, .p-fileupload-choose, .p-inputnumber, .p-password {
    width: 100%;
    outline: none !important;
  }
}
input, p-dropdown {
  border: 1px solid #c5c5c5 !important;
  border-radius: 4px !important;
}
.p-dropdown {
  border: 0;
}
.field > label {
  font-weight: 400 !important;
  font-size: 14px !important
}
