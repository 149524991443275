@import "variables";
.container {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background: #fff;
  z-index: 3
}
.button-block-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.p-element.p-button {
  background: $secondary !important;
  border: none !important;
  padding: 10px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  > span {
    font-weight: 400 !important;
  }
}
.p-element.p-button.p-button-outlined {
  background: #fff !important;
  color: $secondary !important;
  border: 1px solid $secondary !important;
}
